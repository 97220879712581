<template>
    <div class="section">
        <div class="hero">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div v-if="verified" key="verified-success">
                            <h1 class="title">{{$t('Email confirmed')}}</h1>
                            <!--TODO: make few buttons for continue : go to profile or to organizer dashboards-->
                            <b-button tag="router-link" to="/" size="is-big" type="is-primary">{{$t('continue')}}</b-button>

                        </div>
                        <!--<div v-else-if="busy" >
                            <h1 class="title">{{$t('Email verification...')}}</h1>

                            <b-loading :active.sync="busy"></b-loading>

                        </div>-->
                        <div v-else key="verify-email-sent">

                            <h1 class="title">{{$t('Thank you for registration')}}!</h1>

                            <h3 class="subtitle">
                                {{$t('We have sent you an email to confirm your email address. Check your mail and follow the instructions.')}}
                            </h3>

                            <h5>{{$t('Did not get the email')}}?</h5>
                            <b-button class="mt-3" @click.prevent="resend" :loading="emailSending">{{$t('resend')}}</b-button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {RESEND_VERIFY_EMAIL, VERIFY_EMAIL} from "@/store/modules/auth/action-types";
    import {mapActions} from "vuex";

    export default {
        name: "VerifyEmail",
        title(){ return this.$t('Verify email');},
        data: function(){
            return {
                busy: false,
                verified: false,
                emailSending: false,
            };
        },
        methods: {
            ...mapActions('auth', [
                RESEND_VERIFY_EMAIL,
                VERIFY_EMAIL,
            ]),
            verify(){
                this.busy = true;
                this[VERIFY_EMAIL](this.verifyUrl)
                    .then(res => {
                        if (204 === res.status){
                            return this.$router.push({name: 'home'});
                        }
                        this.$gEvent('email_verified');
                        this.verified = true;
                    }).catch(error => {
                        this.$gEvent('email_verification_attempt', {error});
                    })
                    .finally(() => {
                        this.busy = false;
                });
            },
            resend(){
                this.emailSending = true;
                this[RESEND_VERIFY_EMAIL]()
                    .then(() => {
                        this.$buefy.toast.open(this.$t('Verification email sent'));
                    }).finally(() => {
                        this.emailSending = false;
                });

                /*this.busy = true;
                this.axios.post('email/resend').then(res => {
                    this.busy = false;

                    if (202 === res.status){
                        this.$buefy.toast.open('Новое письмо отправлено!')
                    } else if(204 === res.status){
                        this.$buefy.toast.open('Ваш имеил не требует верификации');
                        this.$router.push({name: 'home'});
                    }
                }).catch(error => {
                    if (error){

                       // this.$router.push({name: 'login'})
                    }

                })*/
            },
        },
        computed:{
          verifyUrl(){
            let params = this.$route.query;
            return process.env.VUE_APP_BACKEND_URL + process.env.VUE_APP_API_URL_SUFIX + `/email/verify/${params.id}/${params.hash}?expires=${params.expires}&signature=${params.signature}`;
          },
      },
        created() {
        if (this.$store.state.auth.user.email_verified_at){
            this.$router.push('/');
        }
        if (this.$route.query.hash){
            this.verify();
        }
        this.$Progress.finish();
      },
    };
</script>

<style scoped>

</style>



